

































































































































































































import {
  Component,
  Vue,
  Watch
} from 'vue-property-decorator'
import {
  activeSearchesPerUser,
  callsPerUser,
  groupPropertiesPerUser,
  incomingRecommendationsPerUser,
  insideOfficeVisitsPerUser,
  outgoingRecommendationsPerUser,
  outsideOfficeVisitsPerUser,
  incomingCollaborationsPerUser,
  outgoingCollaborationsPerUser,
  getGroupedAssignations
} from '@/api/statistics'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import DateRangeDropdown from '@/components/DateRangeDropdown/index.vue'
import {
  dateFilter
} from '@/utils/filter'
import PieChart from '@/views/dashboard/components/PieChart.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import { getLocale } from '@/lang'
import {TimeRange} from "@/models/TimeRange";
import {getTimeRangeFromString} from "@/utils";

@Component({
  name: 'CollaboratorsStatistics',
  components: {
    PieChart,
    BarChart,
    ListToolbar,
    DateRangeDropdown,
    FiltersDrawer
  }
})

export default class extends Vue {
  private showFilters = false
  private activeCategory = 'personal_calls'
  private activeVisitsType = 'inside_office_visits'
  private activeTransactionType = 'simple_sale'
  private activeRecType = 'outgoing_recommendations'
  private activeColType = 'outgoing_collaborations'
  private leaseSimple: any = []
  private leaseExclusive: any = []
  private saleSimple: any = []
  private saleExclusive: any = []
  private callsPerUser: any = []
  private insideOfficeVisitsPerUser: any = []
  private outsideOfficeVisitsPerUser: any = []
  private incomingRecommendationsPerUser: any = []
  private outgoingRecommendationsPerUser: any = []
  private incomingCollaborationsPerUser: any = []
  private outgoingCollaborationsPerUser: any = []
  private activeSearchesPerUser: any = []
  private getGroupedAssignations: any = []
  private range = getTimeRangeFromString('last_year')
  private categories: any = {
    visits: {
      fetched: false,
      action: this.getVisitsStats
    },
    recommendations: {
      fetched: false,
      action: this.getRecommendationsStats
    },
    collaborations: {
      fetched: false,
      action: this.getCollaborationsStats
    },
    assignations: {
      fetched: false,
      action: this.getAssignationStats
    },
    active_searches: {
      fetched: false,
      action: this.getSearchesStats
    },
    properties: {
      fetched: false,
      action: this.getPropertiesStats
    },
    personal_calls: {
      fetched: false,
      action: this.getCallsStats
    }
  }

  private filters: any = {
    dateCreated: dateFilter()
  }

  get params() {
    return {
      range: this.range,
      locale: getLocale()
    }
  }

  @Watch('activeCategory')
  private async onCategoryChange(value: string) {
    try {
      await this.categories[value].action()
      this.categories[value].fetched = true
    } catch (e) {}
  }

  private onRangeUpdate(params: any) {
    this.range = params.range
    this.fetch()
  }

  private calculateHeight(length: number) {
    return (length * 35 + 200) + 'px'
  }

  private async getCallsStats() {
    try {
      const { data } = await callsPerUser(this.params)
      this.callsPerUser = data
    } catch (e) {}
  }

  private async getCollaborationsStats() {
    try {
      const incoming = await incomingCollaborationsPerUser(this.params)
      this.incomingCollaborationsPerUser = incoming.data
      const outgoing = await outgoingCollaborationsPerUser(this.params)
      this.outgoingCollaborationsPerUser = outgoing.data
    } catch (e) {}
  }

  private async getRecommendationsStats() {
    try {
      const incoming = await incomingRecommendationsPerUser(this.params)
      this.incomingRecommendationsPerUser = incoming.data
      const outgoing = await outgoingRecommendationsPerUser(this.params)
      this.outgoingRecommendationsPerUser = outgoing.data
    } catch (e) {}
  }

  private async getPropertiesStats() {
    try {
      const { data } = await groupPropertiesPerUser(this.params)
      this.leaseSimple = data.lease_simple.users
      this.leaseExclusive = data.lease_exclusive.users
      this.saleSimple = data.sale_simple.users
      this.saleExclusive = data.sale_exclusive.users
    } catch (e) {}
  }

  private async getVisitsStats() {
    try {
      const inside = await insideOfficeVisitsPerUser(this.params)
      this.insideOfficeVisitsPerUser = inside.data
      const outside = await outsideOfficeVisitsPerUser(this.params)
      this.outsideOfficeVisitsPerUser = outside.data
    } catch (e) {}
  }

  private async getSearchesStats() {
    try {
      const { data } = await activeSearchesPerUser(this.params)
      this.activeSearchesPerUser = data
    } catch (e) {}
  }

  private async getAssignationStats() {
    try {
      const { data } = await getGroupedAssignations(this.params)
      this.getGroupedAssignations = data.map((item: any) => {
        return {
          name: this.$t('dashboard.' + item.available_for + '_' + item.assignation_type),
          value: item.count
        }
      })
    } catch (e) {}
  }

  private fetch() {
    Object.keys(this.categories).forEach((key) => {
      this.categories[key].fetched = false
    })
    this.categories[this.activeCategory].action().then(() => {
      this.categories[this.activeCategory].fetched = true
    })
  }

  created() {
    this.fetch()
  }
}
